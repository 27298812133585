<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <PageHeader title="Keyword Modifiers Overview">
      <template v-slot:actions>
        <q-btn
          label="New Modifier"
          to="/manage/keywords/modifiers/create"
          color="primary"
        />
      </template>
    </PageHeader>

    <DataTable
      table-key="keywordModifiersOverview"
      row-key="id"
      title="Keyword Modifiers"
      :columns="columns"
      :rows="keywordModifiers"
      :loading="loading"
      :downloadable="true"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <template v-slot:actions="row">
        <ActionsDropdownButton
          :actions="[
            {
              action: () => promptDeleteKeywordModifier(row),
              label: 'Delete'
            }
          ]"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import ConfirmationModalDelete from "@/components/UI/ConfirmationModalDelete";
import { Dialog } from "quasar";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import DataTable from "@/components/DataTable";
import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton";
moment.locale("en");

export default {
  name: "KeywordModifiersOverview",
  components: {
    ActionsDropdownButton,
    DataTable,
    PageDynamicBreadcrumb,
    PageHeader
  },
  data() {
    return {
      loading: true,
      keywordModifiersList: [],
      columns: [
        {
          name: "business_account_id",
          label: "Business Account ID",
          field: "business_account_id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "business_account_name",
          label: "Business Account Name",
          field: "business_account_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "brand_id",
          label: "Brand ID",
          field: "brand_id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "brand_name",
          label: "Brand Name",
          field: "brand_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "keyword_modifier",
          label: "Keyword Modifier",
          field: "keyword_modifier",
          sortable: true,
          type: "dimension"
        },
        {
          name: "actions",
          label: "",
          field: "",
          filterable: false
        }
      ]
    };
  },
  computed: {
    keywordModifiers() {
      return this.loading ? [] : this.keywordModifiersList;
    }
  },
  mounted() {
    this.fetchKeywordModifiers();
  },
  methods: {
    fetchKeywordModifiers() {
      axios({
        url: "https://api-v2.jumpfeed.com/internal/keywords/modifiers"
      })
        .then(resp => {
          this.keywordModifiersList = resp.data.data;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    promptDeleteKeywordModifier(modifier) {
      Dialog.create({
        component: ConfirmationModalDelete,
        componentProps: {
          descriptor:
            modifier.brand_name +
            " - " +
            modifier.keyword_modifier +
            " (" +
            modifier.id +
            ")"
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        axios
          .post(
            "https://api-v2.jumpfeed.com/internal/keywords/modifiers/delete",
            {
              id: modifier.id
            }
          )
          .then(() => {
            this.triggerActionOutcomeAlert("delete", true);
            dialog.hide();
            this.fetchKeywordModifiers();
          })
          .catch(err => {
            console.log(err);
            this.triggerActionOutcomeAlert("delete", false);
          })
          .finally(() => {
            dialog.submitting = false;
          });
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
